import React, { useState, useEffect, useCallback } from 'react';
import { list, downloadData, remove, copy } from 'aws-amplify/storage';
import { FaTrash, FaCheck, FaTimes, FaPlus, FaChevronRight, FaSave, FaEdit, FaFile, FaEllipsisV, FaSpinner } from 'react-icons/fa';
import './TileGallery.css';
import mermaid from 'mermaid';
import { getDocument } from 'pdfjs-dist/build/pdf';
import OptionsMenuModal from './OptionsMenuModal';
import Modal from './deleteMenu';
import RenameMindmapModal from './renameModal';
import FormatFileName from '../util/FormatFileName';
import toast, { Toaster } from 'react-hot-toast';
import GettingStarted from './GettingStarted';
import { Container } from '@mui/material';

const cacheKey = 'filesCache';
const cacheExpiryKey = 'cacheExpiry';
const cacheExpiryTime = 60000; // 1 minute

const TileGallery = ({ onPdfSelect, onNewTileClick, onTxtSelect, isFirstMindMap }) => {
    const [pdfList, setPdfList] = useState([]);
    const [txtListAI, setTxtListAI] = useState([]);
    const [txtListInput, setTxtListInput] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({});
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentFileForOptions, setCurrentFileForOptions] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [newFileName, setNewFileName] = useState('');
    const [filePreviews, setFilePreviews] = useState({});
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [editMode, setEditMode] = useState(null);

    const fetchFilesList = useCallback(async () => {
        const cachedFiles = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedFiles && cacheExpiry && Date.now() < cacheExpiry) {
            //console.log('Using cached files');
            const { pdfList, txtListAI, txtListInput, filePreviews } = JSON.parse(cachedFiles);
            setPdfList(pdfList);
            setTxtListAI(txtListAI);
            setTxtListInput(txtListInput);
            setFilePreviews(filePreviews);
            return;
        }

        setLoading(true);
        try {
            const response = await list({
                options: {
                    accessLevel: 'private',
                    listAll: true
                }
            });
            const sortedFiles = response.items.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
            const filesWithValidMermaid = [];
            const filePreviewsPromises = {};
            for (const file of response.items) {
                if (file.key.endsWith('.pdf') || file.key.endsWith('ai_magic.txt') || file.key.endsWith('text_input.txt')) {
                    const summaryFileName = file.key.replace(/\.(pdf|txt)$/, '_summary.txt');
                    try {
                        const downloadResult = await downloadData({
                            key: summaryFileName,
                            options: {
                                accessLevel: 'private'
                            }
                        }).result;
                        const textContent = await downloadResult.body.text();
                        const isValid = await mermaid.mermaidAPI.parse(textContent);
                        if (isValid) {
                            filesWithValidMermaid.push(file.key);
                            filePreviewsPromises[file.key] = fetchFilePreview(file.key);
                        }
                    } catch (error) {
                        console.error('Error fetching or parsing summary file:', error);
                    }
                }
            }
            const filePreviewsResults = await Promise.allSettled(Object.values(filePreviewsPromises));
            const newFilePreviews = {};
            Object.keys(filePreviewsPromises).forEach((key, index) => {
                newFilePreviews[key] = filePreviewsResults[index].status === 'fulfilled' ? filePreviewsResults[index].value : 'Preview not available';
            });

            setFilePreviews(newFilePreviews);
            setPdfList(filesWithValidMermaid);
            setTxtListAI(filesWithValidMermaid.filter(file => file.includes('ai_magic.txt')));
            setTxtListInput(filesWithValidMermaid.filter(file => file.includes('text_input.txt')));

            localStorage.setItem(cacheKey, JSON.stringify({ pdfList: filesWithValidMermaid, txtListAI: filesWithValidMermaid.filter(file => file.includes('ai_magic.txt')), txtListInput: filesWithValidMermaid.filter(file => file.includes('text_input.txt')), filePreviews: newFilePreviews }));
            localStorage.setItem(cacheExpiryKey, Date.now() + cacheExpiryTime);
        } catch (error) {
            //console.error('Error listing files:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchFilesList();
    }, [fetchFilesList]);

    const handleOpenOptionsMenu = (fileName, event) => {
        event.stopPropagation();
        setCurrentFile(fileName);
        const formattedFileName = FormatFileName(fileName);
        setCurrentFileForOptions(formattedFileName);
        setShowOptionsMenu(true);

        const ellipsisBounds = event.currentTarget.getBoundingClientRect();
        let menuX = ellipsisBounds.left;
        let menuY = ellipsisBounds.bottom;

        const menuWidth = 200; 
        const menuHeight = 150;

        setMenuPosition({ top: menuY, left: menuX });
    };

    const handleRenameOptionClick = () => {
        setShowRenameModal(true);
        setShowOptionsMenu(false);
    };

    const handleDeleteOptionClick = (fileName) => {
        
        setShowOptionsMenu(false);
        setShowDeleteModal(true);
    };

    const handleCloseRenameModal = () => {
        setShowRenameModal(false);
    };

    const handleDeleteClick = (fileName, event) => {
        event.stopPropagation();
        setDeleteConfirmation({ ...deleteConfirmation, [fileName]: true });

        setTimeout(() => {
            setDeleteConfirmation({ ...deleteConfirmation, [fileName]: false });
        }, 2500);
    };

    const handleConfirmDelete = async (fileName) => {
        try {
            await remove({ key: fileName, options: { accessLevel: 'private' } });
            setPdfList(prevList => prevList.filter(file => file !== fileName));
            setTxtListAI(prevList => prevList.filter(file => file !== fileName));
            setTxtListInput(prevList => prevList.filter(file => file !== fileName));
            setShowDeleteModal(false);

            const updatedCache = {
                pdfList: pdfList.filter(file => file !== fileName),
                txtListAI: txtListAI.filter(file => file !== fileName),
                txtListInput: txtListInput.filter(file => file !== fileName),
                filePreviews: filePreviews // Assuming the previews remain unchanged
            };
            localStorage.setItem(cacheKey, JSON.stringify(updatedCache));
            localStorage.setItem(cacheExpiryKey, Date.now() + cacheExpiryTime);
        } catch (error) {
          //console.error('Error deleting file:', error);
        }
        toast.success('File deleted successfully');
    };

    const handleFileClick = (fileName) => {
        setSelectedFile(fileName);
        if (fileName.endsWith('.pdf')) {
            onPdfSelect(fileName);
        } else {
            onTxtSelect(fileName);
        }
    };

    const parseFileKey = (fileKey) => {
        let fileType = '';
        let uuid = null;
        let cleanName = '';

        if (fileKey.endsWith('.pdf')) {
            fileType = '.pdf';
            uuid = fileKey.substring(0, 36);
            cleanName = fileKey.substring(37);
        } else if (fileKey.includes('_ai_magic.txt')) {
            fileType = '_ai_magic.txt';
            cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
            uuid = cleanName.substring(0, 36);
            cleanName = cleanName.substring(37);
        } else if (fileKey.includes('_text_input.txt')) {
            fileType = '_text_input.txt';
            cleanName = fileKey.replace(/^(AI_Magic\/|TextInput\/)/, '');
            uuid = cleanName.substring(0, 36);
            cleanName = cleanName.substring(37);
        }

        let namePart = cleanName.replace(new RegExp(`${fileType}$`), '');

        return { uuid, namePart, fileType };
    };

    const fetchFilePreview = async (fileKey) => {
        try {
            const downloadResult = await downloadData({
                key: fileKey,
                options: {
                    accessLevel: 'private'
                }
            }).result;
            const fileBlob = downloadResult.body;
            if (fileKey.endsWith('.pdf')) {
                const arrayBuffer = await fileBlob.arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);
                const pdf = await getDocument({ data: uint8Array }).promise;
                const firstPage = await pdf.getPage(1);
                const content = await firstPage.getTextContent();
                const text = content.items.map(item => item.str).join(' ');
                return text.substring(0, 100);
            } else if (fileKey.endsWith('.txt')) {
                return await fileBlob.text().then(text => text.substring(0, 100));
            } else {
                return 'Unsupported file format';
            }
        } catch (error) {
            //console.error('Error fetching file preview:', error);
            return 'Preview not available';
        }
    };

    const handleRename = async (oldKey, newName) => {
        const { uuid, namePart, fileType } = parseFileKey(oldKey);
        const type = fileType.includes('_ai_magic') ? 'AI_Magic' : fileType.includes('_text_input') ? 'TextInput' : 'pdfs';

        let newKey;
        if (fileType === '.pdf') {
            newKey = `${uuid}-${newName}.pdf`;
        } else {
            newKey = `${type}/${uuid}_${newName}${fileType}`;
        }
        const newSummaryKey = newKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');
        const oldSummaryKey = oldKey.replace(/(\.pdf|\.txt)$/, '_summary.txt');

        try {
            await copy({
                source: { key: oldKey, accessLevel: 'private' },
                destination: { key: newKey, accessLevel: 'private' }
            });
            await remove({ key: oldKey, options: { accessLevel: 'private' } });

            try {
                await copy({
                    source: { key: oldSummaryKey, accessLevel: 'private' },
                    destination: { key: newSummaryKey, accessLevel: 'private' }
                });
                await remove({ key: oldSummaryKey, options: { accessLevel: 'private' } });
            } catch (error) {
                //console.warn(`Summary file issue: ${error.message}`);
            }

            const updateFileList = (list) => list.map(file => file === oldKey ? newKey : file);
            setPdfList(updateFileList(pdfList));
            setTxtListAI(updateFileList(txtListAI));
            setTxtListInput(updateFileList(txtListInput));

            const updatedCache = {
                pdfList: updateFileList(pdfList),
                txtListAI: updateFileList(txtListAI),
                txtListInput: updateFileList(txtListInput),
                filePreviews: filePreviews // Assuming the previews remain unchanged
            };
            localStorage.setItem(cacheKey, JSON.stringify(updatedCache));
            localStorage.setItem(cacheExpiryKey, Date.now() + cacheExpiryTime);

            setEditMode(null);
            setShowRenameModal(false);
        } catch (error) {
            //console.error(`Error renaming file: ${error.message}`);
        }
        toast.success('File renamed successfully');
    };

    const initiateRename = (fileKey) => {
        setEditMode(fileKey);
        const { namePart } = parseFileKey(fileKey);
        setNewFileName(namePart.replace(/_/g, ' '));
    };

    const confirmRename = async (fileKey, newName) => {
        await handleRename(fileKey, newName.trim());
        setEditMode(null);
        setNewFileName('');
        fetchFilesList();
    };

    return (
        <div className="tile-gallery-container">
            {isFirstMindMap && (
                <Container>
                    <GettingStarted />
                </Container>
            )}
            {showOptionsMenu && (
                <OptionsMenuModal
                    isOpen={showOptionsMenu}
                    onClose={() => setShowOptionsMenu(false)}
                    onRename={handleRenameOptionClick}
                    onDelete={() => handleDeleteOptionClick(currentFileForOptions)}
                    style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px`, position: 'fixed' }}
                />
            )}
            {showRenameModal && (
                <RenameMindmapModal
                    isOpen={showRenameModal}
                    onClose={handleCloseRenameModal}
                    currentFileKey={currentFile}
                    fileName={currentFileForOptions}
                    onRename={confirmRename}
                />
            )}
            {showDeleteModal && (
                <Modal
                    isOpen={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => handleConfirmDelete(currentFile)}
                    fileName={currentFileForOptions}
                />
            )}
            <div className="tile-title">My Mind Maps:</div>
            <div className="tile-gallery">
                <div className="tile new-tile" onClick={onNewTileClick}>
                    <FaFile size="66px" />
                    New
                </div>
                {loading && (
                    <div className="tile loading-tile">
                        <FaSpinner size="30px" className="fa-spin" />
                        Loading...
                    </div>
                )}
                {[...pdfList, ...txtListAI, ...txtListInput].map(file => (
                    <div
                        className={`tile ${selectedFile === file ? 'selected' : ''}`}
                        key={file}
                        onClick={() => !editMode && handleFileClick(file)}
                    >
                        {editMode === file ? (
                            <>
                                <input value={newFileName} onChange={(e) => setNewFileName(e.target.value)} />
                                <FaSave onClick={(e) => { e.stopPropagation(); confirmRename(file, newFileName); }} />
                                <FaTimes onClick={(e) => { e.stopPropagation(); setEditMode(null); }} />
                            </>
                        ) : (
                            <>
                                <div className="file-preview">
                                    {filePreviews[file] || 'Loading preview...'}
                                </div>
                                <span className="file-name-tile">{FormatFileName(file)}</span>
                                <div className="file-actions">
                                    <FaEllipsisV onClick={(e) => { e.stopPropagation(); handleOpenOptionsMenu(file, e); }} />
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <Toaster position="top-center" />
        </div>
    );
};

export default TileGallery;