import AWS from 'aws-sdk';

const IsSummaryLoading = async (fileKey, identityId) => {
  if (!identityId || !fileKey) return false;

  const s3 = new AWS.S3({
    accessKeyId: 'AKIATPAUEA4MX7ZIXQOK', // ideally, configure in environment variables
    secretAccessKey: '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', // ideally, configure in environment variables
    region: 'us-east-1'
  });

  const summaryFileName = `private/${identityId}/${fileKey}`;
  try {
    const result = await s3.headObject({
      Bucket: 'mindmappdfstore',
      Key: summaryFileName
    }).promise();
    // Check if metadata "is_summary_loading" exists and its value
    if (result.Metadata && result.Metadata.is_summary_loading) {
      return result.Metadata.is_summary_loading === 'true';
    } else {
      return false;
    }
  } catch (err) {
    console.error('Error checking metadata:', err);
    return false;
  }
};

export default IsSummaryLoading;
