// GenerationConfirmation.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import './GenerationConfirmation.css'
import { handleUpgradeClick } from '../util/HandleUpgrade';
import Modal from 'react-bootstrap/Modal';
import PricingComponent from '../ui-components/PricingComponent';
import { useState } from 'react';
import PricingTiersDisplay from './ResponsivePricingComponent/ResponsivePricing';

const GenerationConfirmation = ({ membershipPlan, monthlyLimit, creditsNeeded, currentCredits, onClose, onConfirm, isPaidMember, resetDate }) => {
  const userHasEnoughCredits = currentCredits >= creditsNeeded;
  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false); // New state for modal visibility

  const togglePricingPopup = () => {         //to toggle state of the pricing component when cliclking on upgrade button
    setPricingPopupOpen(!isPricingPopupOpen);
};

const handleUpgradeNavigation = () => {
    window.open('/pricing', '_blank');
  }


  return (
    <div className="generation-confirmation">
      <div className="confirmation-body">
        <p>Producing this mind map will consume your credits as listed below, please confirm.</p>
        <p>
            <span style={{ color: 'grey' }}>Credits left:</span>
            <span style={{ color: 'black' }}> {currentCredits * 10}</span>
        </p>
        <p>
            <span style={{ color: 'grey' }}>Credits needed:</span>
            <span style={{ color: 'black' }}> {creditsNeeded * 10}</span>
        </p>
        {(creditsNeeded * 10) > 50 ? (
                    <div className="confirmation-error">
                        <p>Maximum Credits you can use per mind map is 50. Please shorten the PDF and try again.</p>
                    </div>
                ) : !userHasEnoughCredits ? (
                    <div className="confirmation-error">
                        <p>You do not have enough credits to generate the mind map.</p>
                        {isPaidMember ? (
                            <p>Your usage will reset on {resetDate}. Alternatively, please shorten the PDF and try again.</p>
                        ) : (
                            <Button className='upgrade' variant="secondary" onClick={handleUpgradeNavigation}>Upgrade now✨</Button>
                        )}
                    </div>
                ) : (
                    <div className="confirmation-actions">
                        <Button className="cancelButton" variant="secondary" onClick={onClose}>Cancel</Button>
                        <Button className="submitButton" variant="primary" onClick={onConfirm}>Map This</Button>
                    </div>
                )}
            </div>
            <Modal show={isPricingPopupOpen} onHide={togglePricingPopup} centered className='pricing-modal'>
                    <Modal.Body>
                    <PricingTiersDisplay currentPlanProps={{monthlyLimit: monthlyLimit, currentPlan: membershipPlan}} />
                    </Modal.Body>
            </Modal>
        </div>
    );
};

export default GenerationConfirmation;