import React, { useState } from 'react';
import './testimonialPopup.css'; // Make sure this CSS file exists

const TestimonialPopupModal = ({ onStartClick }) => {
  const [isVisible, setIsVisible] = useState(true); // Add this line

  const handleClick = () => {
    onStartClick();
    setIsVisible(false); // Hide the popup after the button is clicked
  };

  if (!isVisible) {
    return null; // Don't render anything if the popup is not visible
  }

  return (
    <div className="popup-container">
      <div className="popup-message">
        Loving Map This? Share the love and help us improve! 😍
        <div>
        <button className="start-button" onClick={handleClick}>Start</button>
      </div>
      </div>

    </div>
  );
};

export default TestimonialPopupModal;