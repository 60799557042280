import React from 'react';
import './SummaryCard.css';

// Define a function to render the locked feature button
const GenerateSummaryButton = ({
  isPaidUser, fileExists, isSummaryGenerated, onClick
}) => {
  // Determine the button state and tooltip text
  let buttonDisabled = !isPaidUser || !fileExists || isSummaryGenerated;
  let tooltipText = '';

  if (!isPaidUser) {
    tooltipText = 'Upgrade to Premium to unlock this feature.';
  } else if (!fileExists) {
    tooltipText = 'Please upload a file to generate summaries.';
  } else if (isSummaryGenerated) {
    tooltipText = 'Summary has already been generated.';
  }

  return (
    <div className="button-container">
      <button
        className="ai-summary-button"
        onClick={onClick}
        disabled={buttonDisabled}
      >
        Generate Summaries
        <img
          src={`${process.env.PUBLIC_URL}/${buttonDisabled ? 'lock.png' : 'shine.png'}`}
          alt="Icon"
          className="ai-icon"
        />
      </button>

      {buttonDisabled && (
        <div className="tooltip">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default GenerateSummaryButton;