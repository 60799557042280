import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, Box, Container } from '@mui/material';

const GettingStarted = () => {
    const items = [
        {
            video: process.env.PUBLIC_URL + '/videos/demoStart.mp4',
            heading: 'Create your first mind map 🚀',
            description: 'Simply upload your pdf, notes, or a topic and let us do the rest!'
        },
        {
            video: process.env.PUBLIC_URL + '/videos/demoAddNotes.mp4',
            heading: 'Add your own notes 📝',
            description: 'Customize your mind map by adding your own notes and ideas to each node.'
        },
        {
            video: process.env.PUBLIC_URL + '/videos/demoShare.mp4',
            heading: 'Export or share your mind map 📤',
            description: 'You can download your mind map as an image or share it with your friends and colleagues.'
        }
    ];

    return (
        <Container>
        <Typography variant="h2" component="h2" sx={{ textAlign: 'center', marginBottom: '20px' }}>
        Getting Started with Map This
    </Typography>
        <Carousel
        autoPlay={false}
            navButtonsAlwaysVisible={true}
            navButtonsProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white'
                }
            }}
            indicatorContainerProps={{
                style: {
                    marginTop: '10px'
                }
            }}
            indicatorIconButtonProps={{
                style: {
                    color: 'rgba(0, 0, 0, 0.5)'
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    color: '#f570ff'
                }
            }}
        >
            {items.map((item, index) => (
                <Paper key={index}>
                    <Box
                        component="video"
                        src={item.video}
                        controls
                        autoPlay
                        loop
                        muted
                        alt={`Card ${index + 1}`}
                        sx={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'contain'
                        }}
                    />
                    <Box sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h4" component="h3">
                            {item.heading}
                        </Typography>
                        <Typography variant="body1">{item.description}</Typography>
                    </Box>
                </Paper>
            ))}
        </Carousel>
        </Container>
    );
};

export default GettingStarted;
