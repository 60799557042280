import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Mermaid from '../util/mermaid';
import './MindmapViewer.css';
import NavBar from '../ui-components/LandingPage/Navbar';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Footer from '../ui-components/LandingPage/Footer';
import HamsterLoader from '../ui-components/HamsterLoader';
import CTA from '../ui-components/CTA';
import WallOfLove from '../ui-components/LandingPage/WallOfLove';
import { FaArrowCircleRight } from 'react-icons/fa';

const MindmapViewer = () => {
  const { mindmapId } = useParams();
  const [mindmapData, setMindmapData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };

  const redirectToHome = () => {
    navigate('/');
  }

  useEffect(() => {
    const fetchMindmapData = async () => {
      const url = `https://tu64b5em30.execute-api.us-east-1.amazonaws.com/dev`;  //Changed https://o3i27k9zpl.execute-api.us-east-1.amazonaws.com/dev to https://tu64b5em30.execute-api.us-east-1.amazonaws.com/dev for Staging. Testing Purposes
      setIsLoading(true);

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mindmapId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch mindmap data');
        }

        const data = await response.json();
        const mindmapData = data.body;
        setMindmapData(mindmapData);
      } catch (error) {
        //console.error('Error fetching mindmap data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMindmapData();
  }, [mindmapId]);

  if (isLoading) {
    return (
      <div className='main-layout-mindmap-viewer'>
        <div className='loading-container' style={{backgroundImage: 'url("/backgroundPattern.png")', backgroundSize: 'contain', backgroundRepeat: 'repeat'}}>
          <div className="mindmap-header">
              <div className="mindmap-header-left">
                  <img onClick={redirectToHome} src="/logo-with-name-black.png" alt="Map This Logo without name" className="app-header-image" />
              </div>
              <p className='file-name-app'></p>
              <div className="mindmap-header-right">
                  <button className='make-own-mindmap' onClick={redirectToLogin}>
                  Create your 🫵🏻 Mindmap <FaArrowCircleRight />
                  </button>
              </div>
          </div>
          <div className='hamsterLoader'>
              <HamsterLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="main-layout-mindmap-viewer">
        <div className='content-container' style={{backgroundImage: 'url("/backgroundPattern.png")', backgroundSize: 'contain', backgroundRepeat: 'repeat'}}>
            <div className="mindmap-header">
                        <div className="mindmap-header-left">
                            <img onClick={redirectToHome} src="/logo-with-name-black.png" alt="Map This Logo with name" className="app-header-image" />
                        </div>
                        <p className='file-name-app'></p>
                        <div className="mindmap-header-right">
                            <button className='make-own-mindmap' onClick={redirectToLogin}>
                            Create your 🫵🏻 Mindmap <FaArrowCircleRight />
                            </button>
                        </div>
            </div>
            <div className='mindmap-container' >
                            <Mermaid key={mindmapData ? mindmapData.length : 0} chart={mindmapData} />
            </div>
        </div>
        <WallOfLove />
        <Footer />
    </div>
  );
};

export default MindmapViewer;
