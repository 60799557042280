import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

// Custom React hook to check file existence
const UseFileExistence = (fileKey, identityId) => {
  const [exists, setExists] = useState(null);

  useEffect(() => {
    if (!identityId || !fileKey) return;

    const checkFileExistence = async () => {
      const s3 = new AWS.S3({
        accessKeyId: 'AKIATPAUEA4MX7ZIXQOK', // ideally, configure in environment variables
        secretAccessKey: 'mTdjvikz4F0CIcd0AUR6lYqDGDegbitAgrCzayma',
        region: 'us-east-1'
      });

      const baseFileName = fileKey.replace(/(\.pdf|\.txt)$/, '_summaryFile.txt');
      const summaryFileName = `${baseFileName}`;

      try {
        await s3.headObject({
          Bucket: 'mindmappdfstore',
          Key: summaryFileName
        }).promise();
        setExists(true);
      } catch (err) {
        if (err.code === 'NotFound') {
          setExists(false);
        } else {
          console.error('Error checking file existence:', err);
          setExists(false);
        }
      }
    };

    checkFileExistence();
  }, [identityId, fileKey]);

  return exists;
};

export default UseFileExistence;
