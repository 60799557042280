import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

// Custom React hook to check metadata existence in a file
const IsSummaryGenerated = (fileKey, identityId) => {
  const [metadataExists, setMetadataExists] = useState(null);

  useEffect(() => {
    if (!identityId || !fileKey) return;

    const checkMetadataExistence = async () => {
      const s3 = new AWS.S3({
        accessKeyId: 'AKIATPAUEA4MX7ZIXQOK', // ideally, configure in environment variables
        secretAccessKey: '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',
        region: 'us-east-1'
      });

      const summaryFileName = `${fileKey}`;

      try {
        const result = await s3.headObject({
          Bucket: 'mindmappdfstore',
          Key: summaryFileName
        }).promise();

        // Check if metadata "is_summary_generated" exists and its value
        if (result.Metadata && result.Metadata.is_summary_generated) {
          setMetadataExists(result.Metadata.is_summary_generated === 'true');
        } else {
          setMetadataExists(false);
        }
      } catch (err) {
        console.error('Error checking metadata:', err);
        setMetadataExists(false);
      }
    };

    checkMetadataExistence();
  }, [identityId, fileKey]);

  return metadataExists;
};

export default IsSummaryGenerated;
